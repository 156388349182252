<template>
    <vx-card :title="'View Completed Stock Transfer Order'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row ml-1 mb-1" style="width: 100%;">
            <div class="vx-row mb-6" style="width: 45%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>STO</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input :value="code" :readonly="true" class="w-full"></vs-input>
                </div>
            </div>
            <div class="vx-row mb-6" style="width: 10%"></div>
            <div class="vx-row mb-6" style="width: 45%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Source Warehouse</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input :value="selectedSrcWarehouse" :readonly="true" class="w-full"></vs-input>
                </div>
            </div>
        </div>
        <div class="vx-row ml-1 mb-1" style="width: 100%;">
            <div class="vx-row mb-6" style="width: 45%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Handover Document</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input :value="handoverCode" :readonly="true" class="w-full"></vs-input>
                </div>
            </div>
            <div class="vx-row mb-6" style="width: 10%"></div>
            <div class="vx-row mb-6" style="width: 45%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Dest. Warehouse</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input :value="selectedDestWarehouse" :readonly="true" class="w-full"></vs-input>
                </div>
            </div>
        </div>
        <div class="vx-row ml-1 mb-1" style="width: 100%;">
            <div class="vx-row mb-6" style="width: 45%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Vehicle Number</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input :value="vehicleNumber" :readonly="true" class="w-full"></vs-input>
                </div>
            </div>
            <div class="vx-row mb-6" style="width: 10%"></div>
            <div class="vx-row mb-6" style="width: 45%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Delivery Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input :value="formatDate(deliveryDate)" :readonly="true" class="w-full"></vs-input>
                </div>
            </div>
        </div>
        <div class="vx-row ml-1 mb-1" style="width: 100%;">
            <div class="vx-row mb-6" style="width: 45%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Driver</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input :value="driverName" :readonly="true" class="w-full"></vs-input>
                </div>
            </div>
            <div class="vx-row mb-6" style="width: 10%"></div>
            <div class="vx-row mb-6" style="width: 45%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Receive Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input :value="formatDate(eta)" :readonly="true" class="w-full"></vs-input>
                </div>
            </div>
        </div>
        <div class="vx-row ml-1 mb-1" style="width: 100%;">
            <div class="vx-row mb-6" style="width: 45%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Handover Date</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <vs-input :value="formatDate(handoverDate)" :readonly="true" class="w-full"></vs-input>
                </div>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <vs-table stripe style="width:100%" :data="skuLines">
                    <thead>
                        <tr>
                            <th style="vertical-align: middle;" rowspan="2">SKU Code</th>
                            <th style="vertical-align: middle;" rowspan="2">SKU Name</th>
                            <th style="text-align: center;" colspan="2">Deliver</th>
                            <th style="text-align: center;" colspan="2">Receive</th>
                            <th style="vertical-align: middle;" rowspan="2">Missing Qty (UOM)</th>
                        </tr>
                        <tr>
                            <th>HU</th>
                            <th>Qty</th>
                            <th>HU</th>
                            <th>Qty</th>
                        </tr>
                    </thead>
                    <template slot-scope="">
                        <vs-tr :key="indextr" v-for="(tr, indextr) in skuLines">
                            <vs-td>{{ tr.sku_code }}</vs-td>
                            <vs-td>{{ tr.sku_name }}</vs-td>
                            <vs-td>{{ tr.handle_unit }}</vs-td>
                            <vs-td>{{ tr.quantity }}</vs-td>
                            <vs-td>{{ tr.handle_unit }}</vs-td>
                            <vs-td>{{ tr.quantity }}</vs-td>
                            <vs-td>{{ tr.missing }}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
    </vx-card>
</template>

<script>
import moment from "moment";

export default {
    components: {
    },
    data() {
        return {
            pickingDate: this.date,
            id: null,
            readonly: true,
            code: null,
            status: null,
            handoverCode: null,
            vehicleNumber: null,
            driverName: null,
            handoverDate: null,
            pickingId: 0,
            pickingCode: null,
            selectedSrcWarehouse: null,
            selectedDestWarehouse: null,
            destAddress: null,
            deliveryDate: null,
            eta: null,
            notes: null,
            file: null,
            fileUrl: '',
            skuLines: [],
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/sto-planner/get-complete/" + this.id)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.code = resp.data.code
                        this.status = resp.data.status
                        this.handoverCode = resp.data.handover_code
                        this.vehicleNumber = resp.data.vehicle_number
                        this.driverName = resp.data.driver
                        this.handoverDate = moment(resp.data.handover_date).toDate()
                        this.pickingId = resp.data.picking_id
                        this.pickingCode = resp.data.picking_code
                        this.selectedSrcWarehouse = resp.data.selected_src_warehouse.label
                        this.selectedDestWarehouse = resp.data.selected_dest_warehouse.label
                        this.deliveryDate = moment(resp.data.delivery_date).toDate()
                        this.eta = moment(resp.data.eta).toDate()
                        this.skuLines = resp.data.sku_lines
                        this.$vs.loading.close();
                    } else {
                        console.log(resp)
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleBack() {
            this.$router.push({
                name: "outbound.stock-transfer-order-planner",
                params: {status: 4}
            });
        },
        openTab(uri) {
            window.open(uri)
        }
    },
    watch: {
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>